<template>
  <v-row>
    <v-col cols="6">
      <v-text-field v-model="name" @keyup.enter="submit" label="Название" outlined dense hide-details :disabled="isLoading">
        <template v-slot:append-outer>
          <v-btn icon color="success" :disabled="name.length === 0" @click="submit">
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import get from 'lodash.get';
import { mapActions } from 'vuex';

export default {
  name: 'InfomodelForm',
  props: {
    infomodel: { type: Object, required: true },
  },
  data() {
    return {
      isValid: true,
      isLoading: false,
      name: '',
    };
  },
  watch: {
    infomodel: {
      immediate: true,
      deep: true,
      handler() {
        this.name = get(this.infomodel, 'name');
      },
    },
  },
  methods: {
    ...mapActions('attribute_groups', ['UPDATE_ATTRIBUTE_GROUP']),
    async submit() {
      if (this.name === '') return;
      this.isLoading = true;

      try {
        await this.UPDATE_ATTRIBUTE_GROUP({
          attributeGroupId: this.infomodel.id,
          params: { name: this.name },
        });
        this.$notify.showMessage({ message: 'Инфомодель обновлена', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
