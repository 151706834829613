<template>
  <div>
    <v-col class="my-15 text-center" cols="12" v-if="isLoading">
      <v-progress-circular size="32" indeterminate />
    </v-col>
    <v-col class="text-center" cols="12" v-else-if="hasError">
      <h1>404: инфомодель не найдена</h1>
    </v-col>
    <template v-else>
      <v-col cols="12">
        <div class="d-flex align-center">
          <h1 v-text="pageName" />
        </div>
      </v-col>
      <v-col cols="7">
        <InfomodelForm :infomodel="attributeGroup" />
        <h2 v-text="'Параметры'" />
        <v-row v-for="attribute in attributeGroup.attributes" :key="attribute.id" justify="space-between" align="center" no-gutters>
          <CreateOrUpdateAttribute v-if="editId === attribute.id" :attribute="attribute" @hide="editId = null; update(attributeGroupId)" />
          <div class="title-1 my-3" v-else>
            {{ attribute.name }}
          </div>
          <div>
            <v-tooltip v-if="editId !== attribute.id" bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" :disabled="!attribute" v-bind="attrs" v-on="on" @click="editId = attribute.id" :loading="isLoading"><v-icon>mdi-pencil</v-icon></v-btn>
              </template>
              <span>Редактировать параметр</span>
            </v-tooltip>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="error" :disabled="!attribute" v-bind="attrs" v-on="on" @click="remove(attribute.id)" :loading="isLoading"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
              <span>Удалить параметр из инфомодели</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-col>
      <v-col cols="7">
        <h3 class="mb-2">Добавить параметр</h3>
        <v-row justify="space-between" align="center" no-gutters>
          <div class="mr-3">
            Существующий
          </div>
          <AddAttributeToInfomodelForm :attribute-group-id="attributeGroup.id" />
        </v-row>
        <v-row justify="space-between" align="center" no-gutters>
          <div>
            Создать новый
          </div>
          <CreateOrUpdateAttribute :attribute-group="attributeGroup" />
        </v-row>
        <v-row class="mt-5">
          <router-link to="/attributes/">Все параметры</router-link>
        </v-row>
      </v-col>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import InfomodelForm from '@/components/Infomodel/InfomodelForm';
import CreateOrUpdateAttribute from '@/components/Infomodel/CreateOrUpdateAttribute';
import AddAttributeToInfomodelForm from '@/components/Infomodel/AddAttributeToInfomodelForm';

export default {
  components: {
    InfomodelForm,
    CreateOrUpdateAttribute,
    AddAttributeToInfomodelForm,
  },
  data() {
    return {
      itemsPerPage: 24,
      isLoading: false,
      hasError: false,
      editId: null,
    };
  },
  metaInfo() {
    const name = this.pageName;

    return {
      title: `${name} — Инвентарь`,
    };
  },
  computed: {
    ...mapState('attribute_groups', ['attributeGroup']),
    attributeGroupId() {
      return this.$route.params.attributeGroupId;
    },
    pageName() {
      return this.attributeGroup ? `Инфомодель «${this.attributeGroup.name}»` : '';
    },
  },
  watch: {
    attributeGroupId: {
      immediate: true,
      handler(attributeGroupId) {
        this.update(attributeGroupId);
      },
    },
  },
  methods: {
    async update(attributeGroupId) {
      this.isLoading = true;
      this.hasError = false;

      try {
        await this.FETCH_ATTRIBUTE_GROUP(attributeGroupId);
      } catch (e) {
        this.$notify.showErrorMessage(e.response);
        this.hasError = true;
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
    async remove(attributeId) {
      this.isLoading = true;

      try {
        await this.REMOVE_ATTRIBUTE_FROM_GROUP({
          attributeGroupId: this.attributeGroupId,
          attributeId,
        });
        this.$notify.showMessage({ message: 'Параметр удалён из инфомодели', color: 'success' });
      } catch (e) {
        this.$notify.showErrorMessage(e.response);
        this.hasError = true;
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
    ...mapActions('attribute_groups', ['FETCH_ATTRIBUTE_GROUP', 'REMOVE_ATTRIBUTE_FROM_GROUP']),
  },

};
</script>
