<template>
  <v-row justify="center" align="center" no-gutters>
    <v-autocomplete v-model="model" :items="items" :loading="isLoading" :search-input="search" @keydown="updateSearch" @keyup.enter="submit" return-object label="Поиск параметра" item-text="name" outlined dense hide-details />
    <v-btn icon color="primary" :disabled="!model" @click="submit">
      <v-icon>
        mdi-check
      </v-icon>
    </v-btn>
  </v-row>
</template>

<script>
import debounce from 'lodash.debounce';

import { mapActions, mapState } from 'vuex';

export default {
  name: 'AddAttributeToInfomodelForm',
  props: {
    attributeGroupId: { type: Number, required: true },
  },
  data() {
    return {
      isLoading: false,
      model: null,
      items: [],
      search: null,
    };
  },
  computed: {
    ...mapState('attributes', ['attributes']),
  },
  watch: {
    search: {
      handler(value) {
        if (value) this.searchAttributes(value);
      },
      immediate: true,
    },
  },
  methods: {
    async submit() {
      if (!this.model.id) return;
      this.isLoading = true;

      try {
        await this.ADD_ATTRIBUTE_TO_GROUP({
          attributeGroupId: this.attributeGroupId,
          attributeId: this.model.id,
        });
        this.$notify.showMessage({ message: 'Параметр добавлен', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    async searchAttributes(value) {
      if (value.length <= 3) return;

      this.isLoading = true;

      try {
        this.items = await this.SEARCH_ATTRIBUTE(value);
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
    updateSearch: debounce(function (event) {
      this.search = event.target.value;
    }, 500),
    ...mapActions('attribute_groups', ['ADD_ATTRIBUTE_TO_GROUP']),
    ...mapActions('attributes', ['SEARCH_ATTRIBUTE']),
  },
};
</script>
